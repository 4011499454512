<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="新增人员"
    :visible.sync="$_visible"
    width="520px"
    :close-on-click-modal="false"
    @close="onDialogClose">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="100px"
      size="small">
      <el-form-item label="成员头像" prop="avatarUrl">
        <upload-files
          :showFileList="false"
          :maxSize="4"
          accept="image/png, image/jpg, image/jpeg"
          :readonly="true"
          :showTip="false"
          customClassName="one_image_upload"
          listType="custom"
          @successUpload="(file, res) => form.avatarUrl = res.data.url">
          <el-image
            v-if="form.avatarUrl"
            style="width: 148px; height: 148px;"
            :src="form.avatarUrl"
            fit="cover"
            title="点击再次上传">
          </el-image>
          <div v-else class="uploader-icon">
            <i class="el-icon-plus"></i>
          </div>
        </upload-files>
      </el-form-item>
      <el-form-item label="成员姓名" prop="name">
        <el-input
          maxlength="50"
          show-word-limit
          v-model="form.name"
          placeholder="请输入成员姓名">
        </el-input>
      </el-form-item>
      <el-form-item label="成员介绍" prop="description">
        <el-input
          type="textarea" maxlength="300" show-word-limit
          :rows="3"
          placeholder="请输入成员介绍"
          v-model="form.description">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" :loading="saveBtnLoading" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '@/api'
export default {
  data () {
    return {
      saveBtnLoading: false,
      form: {
        avatarUrl: '',
        name: '',
        description: ''
      },
      rules: {
        avatarUrl: [
          { required: true, message: '请上传成员头像', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入成员姓名', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入成员介绍', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 机构Id
    orgId: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addOrgTeamMember()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取服务详情
    addOrgTeamMember () {
      this.saveBtnLoading = true
      api.addOrgTeamMember({
        orgId: this.orgId,
        ...this.form
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('添加成功！')
          this.$emit('onSubmit', this.$clone(this.form))
          this.$_visible = false
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.saveBtnLoading = false
      })
    },

    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visible = false
    },
    // dialog关闭事件
    onDialogClose () {
      // 重置手段添加项目表单
      this.$refs.form.resetFields()
      this.$emit('onDialogClose')
    }
  }
}
</script>
<style lang="scss" scoped>
  // .one_image_upload {
  //   border: 1px dashed #d9d9d9;
  //   cursor: pointer;
  //   position: relative;
  //   overflow: hidden;
  //   transition: all 0.3s ease;
  //   &:hover {
  //     border-color: #3473E6;
  //   }
  //   .uploader-icon {
  //     width: 120px;
  //     height: 120px;
  //     font-size: 28px;
  //     color: #8c939d;
  //     line-height: 120px;
  //     text-align: center;
  //   }
  // }
</style>
