<!--
  编辑机构信息
    + 只有非挂靠身份才能进行编辑
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="编辑机构信息" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 机构名称 -->
        <div class="content_header">
          <el-image
            style="width: 40px; height: 40px;margin-right:20px;"
            :src="detail.logoUrl"
            fit="cover">
          </el-image>
          <h1>{{ detail.name }}</h1>
        </div>
        <!-- 机构信息 -->
        <div class="org_info module_box tw-mb-40px">
          <div class="module_box_header">
            <h1>机构信息</h1>
          </div>
          <div class="module_box_main">
            <div class="form_item">
              <div class="form_item_label">
                机构简介
              </div>
              <div class="form_item_value">
                <el-input
                  type="textarea" maxlength="300" show-word-limit
                  :rows="5"
                  placeholder="请输入机构简介"
                  @blur="updateOrgDescription"
                  v-model="detail.description">
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <!-- 团队列表 -->
        <div class="team_list module_box">
          <div class="module_box_header">
            <h1>服务团队</h1>
          </div>
          <div class="module_box_main">
            <el-button type="primary" size="small" @click="addMemberDialogVisible = true">新增人员</el-button>
            <div class="list_contnet">
              <div
                class="list_item"
                v-for="(item, index) in teamList"
                :key="item.id">
                <div class="item_header">
                  <h1>{{ index + 1 }}.{{ item.name }}</h1>
                  <i class="el-icon-close" @click="deleteItem(item, index, 'member')"></i>
                </div>
                <div class="item_content">
                  <p class="text_elip" :title="item.description">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="company_list module_box tw-mb-40px">
          <div class="module_box_header">
            <h1>服务供应商</h1>
          </div>
          <div class="module_box_main">
            <el-button type="primary" size="small" @click="addCompanyDialogVisible = true">新增供应商</el-button>
            <div class="list_contnet">
              <div
                class="list_item"
                v-for="(item, index) in providerList"
                :key="item.id">
                <div class="item_header">
                  <h1>{{ index + 1 }}.{{ item.name }}</h1>
                  <i class="el-icon-close" @click="deleteItem(item, index, 'company')"></i>
                </div>
                <div class="item_content">
                  <p class="text_elip" :title="item.business"><span>主营业务</span>{{ item.business }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加成员 -->
    <add-member
      @onSubmit="getOrgTeamList"
      :orgId="orgId"
      :visible.sync="addMemberDialogVisible">
    </add-member>

    <!-- 添加服务供应商 -->
    <add-company
      @onSubmit="getOrgProviderList"
      :orgId="orgId"
      :visible.sync="addCompanyDialogVisible">
    </add-company>
  </div>
</template>

<script>
import * as api from '@/api'
import addMember from './components/add-member'
import addCompany from './components/add-company'
export default {
  data () {
    return {
      loading: false, // 页面loading
      addMemberDialogVisible: false, // 添加成员dialog visible
      addCompanyDialogVisible: false, // 添加服务供应商dialog visible
      // 机构详情
      formData: {
        des: '',
        teamList: [],
        companyList: []
      },
      // 机构详情
      detail: {},
      // 服务团队列表
      teamList: [],
      // 提供商列表
      providerList: []
    }
  },
  computed: {
    // 机构id
    orgId () {
      return this.$route.params.id
    }
  },
  components: {
    addMember,
    addCompany
  },
  created () {
    this.getOrgDetail()
    this.getOrgTeamList()
    this.getOrgProviderList()
  },
  methods: {
    // 获取服务详情
    getOrgDetail () {
      this.detail = this.$clone(this.organization)
    },
    // 获取服务团队列表
    getOrgTeamList () {
      this.loading = true
      api.getOrgTeamList(this.orgId).then(res => {
        if (res.data.code === 0) {
          console.log('服务团队列表', res.data.data)
          this.teamList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取服务提供商列表
    getOrgProviderList () {
      this.loading = true
      api.getOrgProviderList(this.orgId).then(res => {
        if (res.data.code === 0) {
          console.log('服务提供商列表', res.data.data)
          this.providerList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 更新机构简介
    updateOrgDescription () {
      api.updateOrgDescription({
        description: this.detail.description
      }).then(res => {
        if (res.data.code === 0) {
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },

    // 删除成员或服务供应商
    deleteItem (item, index, type) {
      const mgsBoxObj = {
        member: {
          title: '删除成员',
          message: '确定删除该成员？'
        },
        company: {
          title: '删除供应商',
          message: '确定删除该供应商？'
        }
      }

      this.deleteItemBefore(item, index, mgsBoxObj[type], type)
    },
    // 删除成员
    deleteItemBefore (item, index, mgs, type) {
      console.log(mgs)
      this.$msgbox({
        title: mgs.title,
        message: mgs.message,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            if (type === 'member') {
              this.deleteOrgTeamMember(item, index, instance, done)
            } else {
              this.deleteOrgProvider(item, index, instance, done)
            }
          } else {
            done()
          }
        }
      })
    },

    // 确定删除成员
    deleteOrgTeamMember (item, index, instance, done) {
      instance.confirmButtonLoading = true
      api.deleteOrgTeamMember(item.id).then(res => {
        if (res.data.code === 0) {
          this.teamList.splice(index, 1)
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },
    // 确定删除供应商
    deleteOrgProvider (item, index, instance, done) {
      instance.confirmButtonLoading = true
      api.deleteOrgProvider(item.id).then(res => {
        if (res.data.code === 0) {
          this.providerList.splice(index, 1)
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },

    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 1000px;
      margin: 0 auto;
      .content_header {
        display: flex;
        align-items: center;
        height: 66px;
        margin-bottom: 20px;
        & > h1 {
          font-size: 26px;
          font-weight: bold;
          color: #000000;
        }
      }
      .org_info {
        .module_box_main {
          .form_item {
            display: flex;
            .form_item_label {
              width: 140px;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #909399;
            }
            .form_item_value {
              flex: 1;
            }
          }
        }
      }

      // .team_list {
      //   .module_box_main {

      //   }
      // }
    }
  }

  .module_box {
    .module_box_header {
      display: flex;
      align-items: center;
      height: 51px;
      width: 100%;
      border-bottom: 1px solid #DCDFE6;
      & > h1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #303133;
      }
    }
    .module_box_main {
      padding-top: 30px;
      .list_contnet {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        margin: 0 -10px;
        .list_item {
          width: 320px;
          height: 90px;
          background: #FFFFFF;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          margin: 0 10px 20px 10px;
          .item_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 0 10px;
            background-image: url('../../assets/images/detail/price_bg.png');
            background-size: 100% 100%;
            & > h1 {
              font-size: 18px;
              font-weight: bold;
              line-height: 25px;
              color: #303133;
            }
            & > i {
              font-size: 20px;
              cursor: pointer;
              color: #F36A6E;
            }
          }
          .item_content {
            padding: 15px 20px;
            & > p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #606266;
              & > span {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #C0C4CC;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
