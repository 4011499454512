<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="新增供应商"
    :visible.sync="$_visible"
    width="520px"
    :close-on-click-modal="false"
    @close="onDialogClose">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="100px"
      size="small">
      <el-form-item label="供应商logo" prop="avatarUrl">
        <upload-files
          :showFileList="false"
          :maxSize="4"
          accept="image/png, image/jpg, image/jpeg"
          :readonly="true"
          :showTip="false"
          customClassName="one_image_upload"
          listType="custom"
          @successUpload="(file, res) => form.avatarUrl = res.data.url">
          <el-image
            v-if="form.avatarUrl"
            style="width: 148px; height: 148px;"
            :src="form.avatarUrl"
            fit="cover"
            title="点击再次上传">
          </el-image>
          <div v-else class="uploader-icon">
            <i class="el-icon-plus"></i>
          </div>
        </upload-files>
      </el-form-item>
      <el-form-item label="供应商名字" prop="name">
        <el-input
          maxlength="50"
          show-word-limit
          v-model="form.name"
          placeholder="请输入供应商名字">
        </el-input>
      </el-form-item>
      <el-form-item label="主营业务" prop="business">
        <el-input
          maxlength="50"
          show-word-limit
          v-model="form.business"
          placeholder="请输入主营业务">
        </el-input>
      </el-form-item>
      <el-form-item label="供应商介绍" prop="description">
        <el-input
          type="textarea" maxlength="300" show-word-limit
          :rows="3"
          placeholder="请输入供应商介绍"
          v-model="form.description">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" size="small" :loading="saveBtnLoading" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '@/api'
export default {
  data () {
    return {
      saveBtnLoading: false,
      form: {
        avatarUrl: '',
        name: '',
        business: '',
        description: ''
      },
      rules: {
        avatarUrl: [
          { required: true, message: '请上传供应商logo', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入供应商名字', trigger: 'blur' }
        ],
        business: [
          { required: true, message: '请输入主营业务', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入供应商介绍', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addOrgProvider()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 获取服务详情
    addOrgProvider () {
      this.saveBtnLoading = true
      api.addOrgProvider({
        ...this.form
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('添加成功！')
          this.$emit('onSubmit', this.$clone(this.form))
          this.$_visible = false
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.saveBtnLoading = false
      })
    },

    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visible = false
    },
    // dialog关闭事件
    onDialogClose () {
      // 重置手段添加项目表单
      this.$refs.form.resetFields()
      this.$emit('onDialogClose')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
